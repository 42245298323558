/* eslint-disable import/no-anonymous-default-export */
import crazy_white from './crazy_white.svg';
import crazy_black from './crazy_black.svg';
import hexagonCopy from './hexagon-copy.svg';
import hexagonChat from './hexagon-chat.svg';
import creator from './creator.png';
import creator1 from './creator1.jpg';
import creator2 from './creator2.png';
import creator3 from './creator3.jpg';
import creator4 from './creator4.jpg';
import creator5 from './creator5.jpg';
import creatornft from './creatornft.png';
import logo from './logo.png';
import discord from './discord.png';
import instagram from './instagram.png';
import telegram from './telegram.png';
import twitter from './twitter.png';
import facebook from './facebook.png';
import menu from './menu.png';
import cross from './cross.png';
import Search from './Search.png';
import tick from './tick.png';
import moon from './moon.png';
import loadinglight from './loadinglight.gif';
import loadingdark from './loadingdark.gif';
import img01 from './img01.png';
import arrow from './arrow.png';
import blockchain from './blockchain.png';

export default {
    crazy_white,
    crazy_black,
    hexagonCopy,
    hexagonChat,
    creator,
    creator1,
    creator2,
    creator3,
    creator4,
    creator5,
    creatornft,
    logo,
    discord,
    instagram,
    telegram,
    twitter,
    facebook,
    menu,
    cross,
    Search,
    tick,
    moon,
    loadinglight,
    loadingdark,
    img01,
    arrow,
    blockchain,
};
