import React from "react"

const Header = () => {
    return (
        <nav style={{margin: "20px"}}>
            <div className="react-flow header-container">
                <div className="react-flow__shadow_blue">
                    <div className="react-flow__shadow_red">
                        <div className="react-flow__node react-flow__node-turbo nopan selectable">
                            <div className="wrapper gradient">
                                <div className="inner">
                                    <div className="body">
                                        <div className="title">
                                            <h1> Finance NFT Marketplace</h1>
                                            <div className="subline ">
                                                <h1 className="gradient-text">
                                                    Your Token &#11042; Your Vote &#11042; Your Art
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
