import React, {ReactElement, useContext, useEffect, useState} from "react"
import Divider from "../Divider";
import Link from 'next/link'
import axios from 'axios';
import {Context} from "@/components/Context";

const LoginPanel = (): ReactElement => {
    const {currentAccount} = useContext(Context);
    const [redirection, setRedirection] = useState("");

    useEffect(() => {
        try {
            const account = localStorage.getItem('account');
            const signature = localStorage.getItem(`account_signature_${account}`);

            if (!account || !signature) {
                return;
            }
            axios.post('/authenticate/', {
                account: account,
                signature: signature,
            }).then(response => {
                setRedirection(response.data.redirect)
            }).catch(error => {
                console.info('Access denied:', error);
            });
        } catch (error) {
            console.info('Access denied:', error);
        }
    }, [currentAccount]);

    return (
        <div className="panel">
            <Divider/>
            <Link className="black"
                  href={`${redirection}`}>
                <h3>Admin Login</h3>
            </Link>
        </div>
    )
}

export default LoginPanel