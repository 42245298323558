import React, {useState} from "react"
import assets from "@/assets/index";
import Image from 'next/image';
import {copyToClipboard} from "@/utils/extras";

const Footer = () => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div>
            <footer>
                <p>
                    &nbsp;&nbsp;&nbsp;©&nbsp;Illustre&nbsp;2024&nbsp;&#11042;&nbsp;
                    <span className="gradient-text">Donate at&nbsp;</span>
                    <Image src={assets.hexagonCopy}
                           alt="arrow"
                           width={15}
                           height={15}
                           style={{
                               marginLeft: "1px",
                               cursor: "pointer"
                           }}
                           onClick={() => copyToClipboard("0xa6679471Cf7021828Ac9C92A49CdC691fB7f4560", setShowTooltip)}
                    />
                    {!showTooltip && <span
                        className={"gray"}>&nbsp;0xa667</span>}
                    {showTooltip && <span className={"gray"} style={{
                        visibility: showTooltip ? 'visible' : 'hidden',
                        opacity: showTooltip ? 1 : 0,
                        transition: 'opacity 0.5s ease-in-out',
                    }}>&nbsp;Copied!
                        </span>}
                </p>
            </footer>
        </div>
    );
}
export default Footer