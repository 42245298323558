import React, {ReactElement} from "react"
import Divider from "../Divider";
import MainOverview from "./MainOverview";

const MainPanel = (): ReactElement => {
    return (
        <div className="panel">
            <Divider/>
            <MainOverview/>
        </div>
    )
}

export default MainPanel