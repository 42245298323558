import React, {ReactElement} from "react"
import '@/css/mintoverview.css'
import Link from 'next/link'
import Image from 'next/legacy/image';
import {uni1d800} from "@/utils/extras";

const MainOverview = (): ReactElement => {

    return (
        <div>
            <Link className="black" href="/crazy/">
                <h1 className={uni1d800.className + " large"}>𝨗CRAZY𝨗</h1>
                <h1 className={uni1d800.className + " large"}>NFT Collection</h1>
                <h4 className={uni1d800.className + ""}>(Under construction)</h4>
                <h4 className={uni1d800.className + ""}>&nbsp;</h4>
                <Image
                    width="300" height="300"
                    src="/crazy_white.svg"
                    className="rounded-full "
                    objectFit="cover"
                    alt="CRAZY Token Logo"
                    priority
                />
            </Link>
        </div>
    )
}

export default MainOverview;
