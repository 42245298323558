"use client";
import React, {ReactElement, useContext, useEffect, useState} from 'react'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import Header from "@/components/Header";
import MainPanel from "@/components/main/MainPanel";
import '@/css/index.css'
import '@/css/flow.css'
import '@/css/flow-image.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import {FreeMode, Navigation} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import AtropaPanel from "@/components/main/AtropaPanel"
import {Context} from "@/components/Context";
import Footer from "@/components/Footer";
import LoginPanel from "@/components/main/LoginPanel";
import {checkHashAndOpenTab} from "@/utils/hash";

const calculateSlidesPerView = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1080) return 10;
    // Adjust the scaling logic below as needed
    return Math.max(1, Math.floor(screenWidth / 108)); // Example scaling logic
}

const Home = (): ReactElement => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [slidesPerView, setSlidesPerView] = useState(10);
    const {artDataList} = useContext(Context);

    useEffect(() => {
        const handleResize = () => {
            const slidesPerViewNeeded = calculateSlidesPerView()
            if (slidesPerViewNeeded !== slidesPerView) {
                setSlidesPerView(slidesPerViewNeeded);
            }
        }

        // Check hash on mount
        checkHashAndOpenTab(setActiveIndex, artDataList)
        handleResize()

        // Add event listener for hash changes
        window.addEventListener('hashchange', () => checkHashAndOpenTab(setActiveIndex, artDataList), false)
        window.addEventListener('resize', handleResize, false)

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('hashchange', () => checkHashAndOpenTab(setActiveIndex, artDataList), false)
            window.removeEventListener('resize', handleResize, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main>
            <div>
                <Header/>
                <div>
                    <Tabs defaultFocus={false}
                          selectedIndex={activeIndex}
                          onSelect={() => {
                          }}
                    >
                        <Swiper
                            slidesPerView={slidesPerView}
                            spaceBetween={0}
                            freeMode={true}
                            onClick={(index) => {
                                window.location.hash = ``
                                setActiveIndex(index.clickedIndex)
                            }
                            }
                            modules={[FreeMode, Navigation]}
                            navigation={slidesPerView < 10}
                        >
                            <TabList>
                                <SwiperSlide className="no-border"><Tab>Markets</Tab></SwiperSlide>
                                <SwiperSlide className="no-border"><Tab>Atropa</Tab></SwiperSlide>
                                <SwiperSlide className="no-border"><Tab>Admin</Tab></SwiperSlide>
                            </TabList>
                        </Swiper>
                        <div>
                            <TabPanel>
                                <MainPanel/>
                            </TabPanel>
                            <TabPanel>
                                <AtropaPanel/>
                            </TabPanel>
                            <TabPanel>
                                <LoginPanel/>
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>
                <Footer/>
            </div>
        </main>
    )
}

export default Home