import {ArtData} from "@/database/art";
import {ArtState} from "@/database/types";

export const checkHashAndOpenTab = (setActiveIndex: Function, artDataList: ArtData[], proposalData?: ArtData[], setShowRoom?: Function) => {
    const hash = window.location.hash
    const matchCreate = hash.match(/#create/)
    if (matchCreate) {
        setActiveIndex(1)
        return
    }
    const matchMint = hash.match(/#mint/)
    if (matchMint) {
        setActiveIndex(2)
        return
    }
    const matchArt = hash.match(/#art-(\d+)/)
    if (matchArt) {
        const selectedArt: ArtData | undefined = proposalData && proposalData.find(artData => artData.art.id === Number(matchArt[1]));
        const showRoom = hash.match(/#show/)
        if (setShowRoom && showRoom && selectedArt) {
            setShowRoom && setShowRoom(selectedArt)
            return
        }
        setActiveIndex(1)
        return
    }
    const matchAuction = hash.match(/#auction/)
    if (matchAuction) {
        setActiveIndex(3)
        return
    }
    const matchMarket = hash.match(/#market/)
    if (matchMarket) {
        setActiveIndex(4)
        return
    }
    const matchNft = hash.match(/#nft-(\d+)/)
    if (matchNft) {
        const selectedArt: ArtData | undefined = artDataList.find(artData => artData.art.tokenId === Number(matchNft[1]));
        const showRoom = hash.match(/#show/)
        if (setShowRoom && showRoom && selectedArt) {
            setShowRoom && setShowRoom(selectedArt)
            return
        }
        if (selectedArt?.state === ArtState.AUCTION || selectedArt?.state === ArtState.FORCED_AUCTION) {
            setActiveIndex(3)
        } else {
            setActiveIndex(4)
        }
        return
    }
    const matchVotes = hash.match(/#votes/)
    if (matchVotes) {
        setActiveIndex(5)
        return
    }
    const matchClaims = hash.match(/#claims/)
    if (matchClaims) {
        setActiveIndex(6)
        return
    }
    const matchPortfolio = hash.match(/#portfolio/)
    if (matchPortfolio) {
        setActiveIndex(7)
        return
    }
    const matchAirdrop = hash.match(/#airdrop/)
    if (matchAirdrop) {
        setActiveIndex(0)
        return
    }
    const matchDex = hash.match(/#dex/)
    if (matchDex) {
        setActiveIndex(8)
        return
    }
    const matchHelp = hash.match(/#help/)
    if (matchHelp) {
        setActiveIndex(9)
        return
    }
    const matchStats = hash.match(/#stats/)
    if (matchStats) {
        setActiveIndex(10)
        return
    }
}
