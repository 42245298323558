import localFont from 'next/font/local'

export const uni1d800 = localFont({
    src: './Uni1D800Suttonsignwriting.ttf',
    display: 'swap',
})

export const nonBreakingSpace = '\u00A0';

export const copyToClipboard = async (text: string, setShowAnyTooltip: Function) => {
    try {
        await navigator.clipboard.writeText(text);
        setShowAnyTooltip(true);
        setTimeout(() => setShowAnyTooltip(false), 5000);
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
}

export const custodial: string = '0x6BA1bFBED256b8A9245C6332dC6e0F51D6ca1053'
