import React, {ReactElement} from "react"
import Divider from "../Divider";
import Link from 'next/link'

const AtropaPanel = (): ReactElement => {
    return (
        <div className="panel">
            <Divider/>
            <Link className="black"
                  href="https://docs.google.com/spreadsheets/d/1Vu5Nf0hTyjiq_4p7BINlDzSuM-TRiNHMICtYmxH7eJo/edit#gid=0">
                <h3>Token overview of the Atropa eco-system on Pulsechain</h3>
            </Link>
        </div>
    )
}

export default AtropaPanel